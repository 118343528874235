export const initCircledFocus = (container: HTMLElement, triggerElement?: HTMLElement) => {
    const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]';
    
    const getFocusableElements = (element: HTMLElement): HTMLElement[] => {
        return Array.from(element.querySelectorAll(focusableElementsString));
    };

    // Set initial focus
    const focusableElements = getFocusableElements(container);
    if (focusableElements.length > 0) {
        focusableElements[0].focus();
    }

    const focusTrap = (event: KeyboardEvent) => {
        if (event.key !== 'Tab') return;

        const focusableElements = getFocusableElements(container);
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
        }
    };

    container.addEventListener('keydown', focusTrap);

    return () => {
        container.removeEventListener('keydown', focusTrap);
        // Restore focus to trigger element if provided
        if (triggerElement) {
            triggerElement.focus();
        }
    };
};