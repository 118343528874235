import { initCircledFocus } from '../utils/circled-focus';

document.addEventListener('DOMContentLoaded', function () {
    const popup = document.querySelector('[data-book-table-popup]') as HTMLElement;
    const triggers = document.querySelectorAll('[data-open-book-table]');
    const closeElements = document.querySelectorAll('[data-close-popup]');

    if (!popup || !triggers.length) return;

    let removeCircledFocus: (() => void) | null = null;

    const openPopup = (e: Event) => {
        const trigger = e.currentTarget as HTMLElement;
        popup.classList.add('is-active');
        document.body.style.overflow = 'hidden';
        removeCircledFocus = initCircledFocus(popup, trigger);
    };

    const closePopup = (e?: Event) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popup.classList.remove('is-active');
        document.body.style.overflow = '';
        
        if (removeCircledFocus) {
            removeCircledFocus();
            removeCircledFocus = null;
        }
    };

    // Add click listeners to triggers
    triggers.forEach(trigger => {
        trigger.addEventListener('click', openPopup);
    });

    // Add click listeners to close elements
    closeElements.forEach(element => {
        element.addEventListener('click', closePopup);
    });

    // Close on escape key
    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && popup.classList.contains('is-active')) {
            closePopup();
        }
    });
});
